'use strict';

import React from 'react';
import AOS from 'aos';
import $ from 'jquery';
import translate from "../translate";

import ContactCTA from '../contactCTA';

import {team} from "../../../cnf/team";
import arrow from '../../assets/icon/arrow.svg';
import Parse from "parse";
import _ from "lodash";

class About extends  React.Component{
    constructor(props) {
        super(props);

        this.state = {
            activeItemIndex: 0,
            teamMembers: []
        }
    }

    async componentDidMount() {
        await this.getTeamMembers(() => {
            AOS.init({
                duration: 2000,
                delay: 1000
            });
            const teamSlider = $('.team__slider');
            teamSlider.slick({
                speed: 800,
                rows: 100,
                variableWidth: true,
                infinite: false,
                slidesPerRow: 4,
                swipeToSlide: false,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 1300,
                        settings: {
                            slidesPerRow: 3
                        }
                    },
                    {
                        breakpoint: 950,
                        settings: {
                            slidesPerRow: 2
                        }
                    },
                    {
                        breakpoint: 670,
                        settings: {
                            slidesPerRow: 1
                        }
                    }
                ]
            });
        });
    }

    openDescriptionBlock(id) {
        const element = document.getElementById(id);

        if(element.classList.contains('active')) {
            element.classList.remove('active')
        } else {
            element.classList.add('active')
        }
    }

    async getTeamMembers(callback){
        let response = await Parse.Cloud.run('creator-business-website-team-member-report', {refresh: true});
        let teamMembers = response?.teamMembers?.filter(item => item.public === 'Public') ?? [];

        teamMembers = teamMembers.sort((a, b) => {
            if(a.order > b.order) return 1;
            if(a.order < b.order) return -1;
            return 0;
        })
        this.setState({teamMembers}, callback);
    }

    render() {
        const {t} = this.props;
        let language = this.props.i18n.resolvedLanguage;

        return (
            <>
                <section className='title-section first-screen'>
                    <div className='wrapper'>
                        <div className='title-section__main-info'>
                            <h2>
                                {t('page-4-label-0', {delay: false})}
                            </h2>
                        </div>
                    </div>
                </section>
                <section className='big-img'>
                    <div className='big-img__img-block'>
                        <img className='img img--big'    loading={'lazy'} src='https://ik.imagekit.io/sh/cleveron_ch/IMG_9668_kfEgNPvjX.JPEG?tr=w-1600' alt='' />
                        <img className='img img--medium' loading={'lazy'} src='https://ik.imagekit.io/sh/cleveron_ch/IMG_9668_kfEgNPvjX.JPEG?tr=w-1400' alt='' />
                        <img className='img img--small'  loading={'lazy'} src='https://ik.imagekit.io/sh/cleveron_ch/IMG_9668_kfEgNPvjX.JPEG?tr=w-700' alt='' />
                    </div>
                </section>
                <section className='faq' data-aos='fade-left'>
                    <div className='wrapper'>
                        <ul className='faq__list'>
                            <li id='first' className='faq__item active'>
                                <h3 className='faq__quastion'>{t('page-4-label-1')}</h3>
                                <div className='faq__answer'>
                                    {t('page-4-label-2')}
                                </div>
                                <div className='faq__open-btn' onClick={() => this.openDescriptionBlock('first')}>
                                    <span className='faq__btn-line' />
                                </div>
                            </li>
                            <li id='second' className='faq__item'>
                                <h3 className='faq__quastion'>{t('page-4-label-3')}</h3>
                                <div className='faq__answer'>
                                    {t('page-4-label-4')}
                                </div>
                                <div className='faq__open-btn' onClick={() => this.openDescriptionBlock('second')}>
                                    <span className='faq__btn-line' />
                                </div>
                            </li>
                            <li id='third' className='faq__item'>
                                <h3 className='faq__quastion'>{t('page-4-label-5')}</h3>
                                <div className='faq__answer'>
                                    {t('page-4-label-6')}</div>
                                <div className='faq__open-btn' onClick={() => this.openDescriptionBlock('third')}>
                                    <span className='faq__btn-line' />
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className='team'>
                    <div className='wrapper'>
                        <h3>
                            {t('page-4-label-13')}
                        </h3>
                    </div>

                    <div id='team-slider' className='team__slider'>
                        {this.state.teamMembers.map((person, i) => (
                            <div key={i}>
                                <div>
                                    <div className='team__item' style={{marginTop: 40}}>
                                        <div className='person'>
                                            <img src={`${person.imagekit_link.url}?tr=w-600`} loading="lazy" alt='' className='person__img' />
                                            <div className='person__name'>{person.Name.display_value}</div>
                                            <div className='person__position'>{person[`position_${language}`] || person[`position_de`]}</div>
                                            <a href={`mailto:${person.Email}`}
                                               className="person__email">{person.Email}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
                <ContactCTA />
            </>
        )
    }
}

export default translate(About);