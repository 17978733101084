'use strict';

import React from 'react';

import translate from "../translate";
import Language from '../language';
import paths from '../../lib/paths';
import axios from "axios";
import db from "../../lib/structure";
import Parse from 'parse';

class Header extends React.Component{
    constructor(props) {
        super(props);

         this.state = {
            isHamburgerActive: false,
            isMobileMenuActive: false,
            isReferencesMenuActive: false,
            caseStudies: []
        };

        this.loadCaseStudies = this.loadCaseStudies.bind(this);
    }

    async componentDidMount() {
        await this.loadCaseStudies();
    }

    async componentDidUpdate(prevProps, prevState) {
        if(this.state.caseStudies.length === 0)
            await this.loadCaseStudies();
    }


    async loadCaseStudies(){
        let response = await Parse.Cloud.run(
            'crm-case-studies',
            Object.fromEntries(new URLSearchParams(this.props.history.location.search))
        );

        this.setState({caseStudies: response.caseStudies});
    }

    render() {
        const {isMobileMenuActive, isHamburgerActive, isReferencesMenuActive, caseStudies} = this.state;
        const {t} = this.props;

        return (
            <React.Fragment>
                <nav className={`mobile-nav ${isHamburgerActive ? 'active' : ''}`}>
                    <ul className='mobile-nav__list big'>
                        <li  onClick={() => this.setState({isMobileMenuActive: !isMobileMenuActive})} className={`mobile-nav__item ${isMobileMenuActive ? 'active' : ''}`}>
                            <span className='mobile-nav__link'>{t('Produkte')}</span>
                            <div
                                onClick={() => this.setState({isMobileMenuActive: !isMobileMenuActive})}
                                className={`mobile-nav__open-btn ${isMobileMenuActive ? 'active' : ''}`}>
                                <span className='mobile-nav__btn-line' />
                            </div>
                            <ul className='mobile-nav__sub-list'>
                                <li className='mobile-nav__sub-item'>
                                    <a href={paths.heating} className='mobile-nav__sub-link'>
                                        {t('Heizungsoptimierung')}
                                    </a>
                                </li>
                                <li className='mobile-nav__sub-item'>
                                    <a href={paths.air} className='mobile-nav__sub-link'>
                                        {t('Luftqualität')}
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className='mobile-nav__item'>
                            <a href={paths.about} className='mobile-nav__link'>{t('Über uns')}</a>
                        </li>
                        <li  onClick={() => this.setState({isReferencesMenuActive: !isReferencesMenuActive})} className={`mobile-nav__item`}>
                            <a href={paths.caseStudy} className='mobile-nav__link'>{t('Case studies')}</a>
                        </li>
                        <li className='mobile-nav__item'>
                            <a href={paths.partnership} className='mobile-nav__link'>{t('Partnerschaften')}</a>
                        </li>
                        <li className='mobile-nav__item'>
                            <a href={paths.media} className='mobile-nav__link'>{t('Blog')}</a>
                        </li>
                        {/*
                        <li className='mobile-nav__item'>
                            <a href={paths.media} className='mobile-nav__link'>{t('Medien')}</a>
                            <div className='mobile-nav__open-btn'>
                                <span className='mobile-nav__btn-line' />
                            </div>
                            <ul className='mobile-nav__sub-list'>
                                <li className='mobile-nav__sub-item'>
                                    <a href={paths.media} className='mobile-nav__sub-link'>
                                        {t('Blog')}
                                    </a>
                                </li>
                                <li className='mobile-nav__sub-item'>
                                    <a href={paths.media} className='mobile-nav__sub-link'>
                                        {t('Press')}
                                    </a>
                                </li>
                                <li className='mobile-nav__sub-item'>
                                    <a href={paths.video} className='mobile-nav__sub-link'>
                                        {t('Video')}
                                    </a>
                                </li>
                            </ul>
                        </li>
                        */}
                    </ul>
                    <ul className='mobile-nav__list small'>
                        <li className='mobile-nav__item'>
                            <a href={paths.contact} className='mobile-nav__link'>{t('Kontakt')}</a>
                        </li>
                        <li className='mobile-nav__item adress'>
                            <div className='adress__box'>
                                <div>Cleveron AG</div>
                                <div>Bernapark 28<span className='no-desctop'>,</span></div>
                                <div>3066 Stettlen</div>
                            </div>
                            <a className='adress__email' href='mailto:info@cleveron.ch'>info@cleveron.ch</a>
                        </li>
                        <li className={'mobile-nav__item'} style={{marginTop: 20}}>
                            <a href={paths.demoRequest} className='demo-request-button'>
                                {t('component-4-label-1')}
                            </a>
                        </li>
                        <li className='mobile-nav__item language' style={{marginTop: 30}}>
                            <Language />
                        </li>
                        <li className='mobile-nav__item social'>
                            <div className='social__box'>
                                <a href="https://www.linkedin.com/company/cleveron-ag/" target={'_blank'} className="social__link">
                                    <svg className="social__img" xmlns="http://www.w3.org/2000/svg" width="20.965"
                                         height="20.039"
                                         viewBox="0 0 20.965 20.039">
                                        <g id="linkedin" transform="translate(0 0.668)">
                                            <path className="fill"
                                                  d="M2.542-.668A2.342,2.342,0,1,0,2.483,4h.029a2.343,2.343,0,1,0,.03-4.672Zm0,0"/>
                                            <path className="fill" d="M8.109,198.313H12.6v13.521H8.109Zm0,0"
                                                  transform="translate(-7.844 -192.462)"/>
                                            <path className="fill"
                                                  d="M229.759,188.625a5.63,5.63,0,0,0-4.05,2.278v-1.961h-4.494v13.521h4.494v-7.551a3.08,3.08,0,0,1,.148-1.1,2.46,2.46,0,0,1,2.306-1.643c1.626,0,2.277,1.24,2.277,3.058v7.233h4.494v-7.753C234.933,190.558,232.716,188.625,229.759,188.625Zm0,0"
                                                  transform="translate(-213.968 -183.092)"/>
                                        </g>
                                    </svg>
                                </a>
                                <a href="https://twitter.com/@CleveronAg" target="_blank" className='social__link'>
                                    <svg className='social__img' id='twitter' xmlns='http://www.w3.org/2000/svg'
                                         width='24.732' height='20.09'
                                         viewBox='0 0 24.732 20.09'>
                                        <path className='fill' id='Shape-2' data-name='Shape'
                                              d='M24.732,2.377a10.132,10.132,0,0,1-2.913.8A5.1,5.1,0,0,0,24.049.371,10.228,10.228,0,0,1,20.825,1.6,5.078,5.078,0,0,0,12.18,6.228,14.4,14.4,0,0,1,1.723.927,5.079,5.079,0,0,0,3.293,7.7,5.074,5.074,0,0,1,1,7.063v.063A5.079,5.079,0,0,0,5.064,12.1a5.139,5.139,0,0,1-1.337.178,4.87,4.87,0,0,1-.955-.094,5.076,5.076,0,0,0,4.738,3.524,10.179,10.179,0,0,1-6.3,2.168A10.783,10.783,0,0,1,0,17.807,14.341,14.341,0,0,0,7.777,20.09,14.335,14.335,0,0,0,22.212,5.655L22.195,5A10.132,10.132,0,0,0,24.732,2.377Z'/>
                                    </svg>
                                </a>
                                <a href="https://www.facebook.com/CleveronAg/?ref=br_rs" target="_blank" className='social__link'>
                                    <svg className='social__img' id='facebook' xmlns='http://www.w3.org/2000/svg'
                                         width='12.323' height='23.277'
                                         viewBox='0 0 12.323 23.277'>
                                        <path className='fill' id='Facebook-2' data-name='Facebook'
                                              d='M8,23.277V12.66h3.632l.545-4.139H8V5.879c0-1.2.338-2.014,2.091-2.014h2.233V.162A30.858,30.858,0,0,0,9.069,0C5.848,0,3.643,1.928,3.643,5.469V8.521H0V12.66H3.643V23.277Z'/>
                                    </svg>
                                </a>
                                <a href='https://www.youtube.com/channel/UCT0-SxAVWOpZz0VM91MGXqw' target="_blank" className='social__link'>
                                    <svg className="social__img" aria-hidden="true" focusable="false"
                                         data-prefix="fab" data-icon="youtube"
                                         role="img"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                        <path className="fill"
                                              d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z">
                                        </path>
                                    </svg>
                                </a>
                                <a href="https://www.instagram.com/cleveronag/" target="_blank" className='social__link'>
                                    <svg className='social__img' id='instagram' xmlns='http://www.w3.org/2000/svg'
                                         width='26.187' height='26.187'
                                         viewBox='0 0 26.187 26.187'>
                                        <path className='fill' id='Mask-2' data-name='Mask'
                                              d='M18.961,26.187H7.226A7.235,7.235,0,0,1,0,18.961V7.226A7.235,7.235,0,0,1,7.226,0H18.961a7.235,7.235,0,0,1,7.226,7.226V18.961A7.235,7.235,0,0,1,18.961,26.187ZM7.226,2.323a4.909,4.909,0,0,0-4.9,4.9V18.961a4.909,4.909,0,0,0,4.9,4.9H18.961a4.909,4.909,0,0,0,4.9-4.9V7.226a4.909,4.909,0,0,0-4.9-4.9Zm5.867,17.629a6.858,6.858,0,1,1,6.858-6.858A6.866,6.866,0,0,1,13.094,19.952Zm0-11.356a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,13.094,8.6Z'
                                              fill='#212123'/>
                                    </svg>
                                </a>
                            </div>
                        </li>
                    </ul>
                </nav>
                <header className='header'>
                    <div className='header__fixed'>
                        <div className='wrapper'>
                            <div className='header__row'>
                                <a href={paths.home} className='logo'>
                                    <img src='https://ik.imagekit.io/sh/cleveron_ch/logo_JNMCm01DM.svg?updatedAt=1634907854940' loading={'lazy'} alt='' className='logo__img' />
                                </a>
                                <nav className='main-nav'>
                                    <ul className='main-nav__list'>
                                        <li className='main-nav__item sub-menu'>
                                            <a onClick={() => this.props.history.push(paths.products)}  className='main-nav__link'>
                                                {t('Technologien')}
                                            </a>
                                            <ul className='main-nav__sub-list'>
                                                <li className='main-nav__sub-item'>
                                                    <a onClick={() => this.props.history.push(paths.heating)} className='main-nav__sub-link'>
                                                        {t('Heizungsoptimierung')}
                                                    </a>
                                                </li>
                                                <li className='main-nav__sub-item'>
                                                    <a onClick={() => this.props.history.push(paths.air)} className='main-nav__sub-link'>
                                                        {t('Luftqualität')}
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className='main-nav__item'>
                                            <a onClick={() => this.props.history.push(paths.about)} className='main-nav__link'>
                                                {t('Über uns')}
                                            </a>
                                        </li>
                                        <li className='main-nav__item sub-menu'>
                                            <a onClick={() => this.props.history.push(paths.caseStudy)} className='main-nav__link'>
                                                {t('Case studies')}
                                            </a>
                                            <ul className='main-nav__sub-list'>
                                                {
                                                    this.state.caseStudies.splice(0, 5).map((caseStudy, i) => {
                                                        return <li key={i} className='main-nav__sub-item'>
                                                            <a href={`/case-study/${caseStudy.id}`} className='main-nav__sub-link'>
                                                                {caseStudy[db.ZohoBuilding.NAME]}
                                                            </a>
                                                        </li>;
                                                    })
                                                }
                                            </ul>
                                        </li>
                                        <li className='main-nav__item'>
                                            <a onClick={() => this.props.history.push(paths.partnership)} className='main-nav__link'>
                                                {t('Partnerschaften')}
                                            </a>
                                        </li>
                                        <li className='main-nav__item'>
                                            <a onClick={() => this.props.history.push(paths.media)} className='main-nav__link'>
                                                {t('Blog')}
                                            </a>
                                        </li>
                                        {/*
                                        <li className='main-nav__item sub-menu'>
                                            <a onClick={() => this.props.history.push(paths.media)} className='main-nav__link'>
                                                {t('Medien')}
                                            </a>
                                            <ul className='main-nav__sub-list'>
                                                <li className='main-nav__sub-item'>
                                                    <a onClick={() => this.props.history.push(paths.media)} className='main-nav__sub-link'>
                                                        {t('Blog')}
                                                    </a>
                                                </li>
                                                <li className='main-nav__sub-item'>
                                                    <a onClick={() => this.props.history.push(paths.media)} className='main-nav__sub-link'>
                                                        {t('Press')}
                                                    </a>
                                                </li>
                                                <li className='main-nav__sub-item'>
                                                    <a onClick={() => this.props.history.push(paths.media)} className='main-nav__sub-link'>
                                                        {t('Video')}
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        */}
                                        <li className='main-nav__item'>
                                            <a onClick={() => this.props.history.push(paths.contact)} className='main-nav__link'>
                                                {t('Kontakt')}
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                                <div className='language-container'>
                                    <Language />
                                </div>
                                <a href={paths.demoRequest} className='demo-request-button'>
                                    {t('component-4-label-1')}
                                </a>
                                <div className={`hamburger  ${isHamburgerActive ? 'active' : ''}`}
                                     onClick={() => this.setState({isHamburgerActive: !isHamburgerActive})}
                                >
                                    <span className='hamburger__line hamburger__line--small' />
                                    <span className='hamburger__line hamburger__line--big' />
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                </React.Fragment>
        );
    }
}

export default translate(Header);

