'use strict';

import React from 'react';
import AOS from "aos";
import Butter from 'buttercms';

import {cookiePopup, preloader, resize} from '../../lib/ui-utils';
import {media} from '../../../cnf/media';
import translate from '../translate';
import ContactCTA from "../contactCTA";
import paths from '../../lib/paths'
import moment from "moment";
import PropTypes from "prop-types";
import arrowIcon from '../../assets/icon/arrow.svg';

const butter = Butter('e68de44558c65c5f13908ff04132856386aaa93b');

class Media extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            checked: media.default,
            posts: [],
            categories: [],
            tags: [],
            showContent: false
        }

        this.loadRecentPosts = this.loadRecentPosts.bind(this);
        this.loadCategories = this.loadCategories.bind(this);
        this.loadTags = this.loadTags.bind(this);
    }

    componentDidMount() {
        AOS.init({
            duration: 1500,
            delay: 1000
        });

        this.loadRecentPosts();
        this.loadCategories();
        this.loadTags();
        this.initUi();

        this.setState({showContent: true});

        moment.locale(this.props.i18n.language);
    }

    initUi(){
        // preloader();
        // resize();
        cookiePopup();
    }

    loadRecentPosts(){
        let languageMap = {
            it: 'italiano',
            de: 'deutsch'
        };
        butter.tag.retrieve(this.props.match.params.tag || languageMap[this.props.i18n.language] || 'deutsch', {include: 'recent_posts'})
            .then(response => {

                this.setState({posts: response.data.data.recent_posts})
            })
            .catch(console.error);

    }

    loadRecentPostsByCategory(category) {
        butter.category.retrieve(category, {include: 'recent_posts'})
            .then(response => {
                this.setState({posts: response.data.data.recent_posts})
            })
            .catch(e => {
                console.error(e)
                this.setState({posts: []})
            });

    }

    loadCategories(){
        butter.category.list()
            .then(response => {
                this.setState({categories: response.data.data})
            });
    }

    loadTags(){
        butter.tag.list()
            .then(response => {
                this.setState({tags: response.data.data})
            });
    }

    setCheckedState(newMedia) {
        this.setState({checked: newMedia});
        if(newMedia === media.default) {
            this.loadRecentPosts();
        } else {
            this.loadRecentPostsByCategory(newMedia);
        }
    }

    render() {
        const {checked} = this.state;
        const {t} = this.props;
        return (
            <main className="main">
                <section className="sidebar-section media bg-gray">
                    <div className="wrapper">
                        <div className="media__heading">
                            <h2 data-aos="fade-right">{t('Blog')}</h2>
                            {
                                this.props.match.params.tag && <div className="media__filter" data-aos="fade-left">
                                    <label className="radio-area" onClick={() => this.setCheckedState(media.default)}>
                                        <div className="radio-area__placeholder">{t('Tag:')} {this.props.match.params.tag}</div>
                                    </label>
                                </div>
                            }

                            {/*
                            <div className="media__filter" data-aos="fade-left">
                                <label className="radio-area" onClick={() => this.setCheckedState(media.default)}>
                                    <input type="radio" name="filter" className="radio-area__input" checked={checked === media.default} />
                                        <div className="radio-area__radio" />
                                        <div className="radio-area__placeholder">{t('All')}</div>
                                </label>
                                <label className="radio-area" onClick={() => this.setCheckedState(media.news)}>
                                    <input type="radio" name="filter" className="radio-area__input" checked={checked === media.news} />
                                        <div className="radio-area__radio" />
                                        <div className="radio-area__placeholder">{t('News')}</div>
                                </label>
                                <label className="radio-area" onClick={() => this.setCheckedState(media.video)}>
                                    <input type="radio" name="filter" className="radio-area__input" checked={checked === media.video} />
                                        <div className="radio-area__radio" />
                                        <div className="radio-area__placeholder">{t('Video')}</div>
                                </label>
                                <label className="radio-area" onClick={() => this.setCheckedState(media.press)}>
                                    <input type="radio" name="filter" className="radio-area__input" checked={checked === media.press} />
                                        <div className="radio-area__radio" />
                                        <div className="radio-area__placeholder">{t('Press')}</div>
                                </label>
                            </div>
                            */}

                        </div>

                        {this.state.posts.map((post, i) => (
                            <div key={i} className="media__item">
                                <a onClick={() => this.props.history.push(`/news/${post.slug}`)} className="media__img-link" data-aos="fade-left">
                                    <img src={post.featured_image.replace('https://cdn.buttercms.com', 'https://cdn.buttercms.com/resize=width:400')} loading={'lazy'} alt="" className="media__img" />
                                </a>
                                <div className="media__item-info-box">
                                    <a onClick={() => this.props.history.push(`/news/${post.slug}`)} className="h3">{post.title}</a>
                                    <div className="media__date">{moment(post.published).format('LL')}</div>
                                    <div className="media__item-info">{post.meta_description}</div>
                                    <div className="arrows arrows--link">
                                        <a onClick={() => this.props.history.push(`/news/${post.slug}`)} className="arrows__item arrows__item--next">
                                            <img className="arrows__arrow" src={arrowIcon} alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {
                            this.state.posts.length > 0 && <div className="media__footer">
                                <span className="btn">{t('Show more')}</span>
                            </div>
                        }
                    </div>
                </section>
                <ContactCTA />
            </main>
        );
    }
}

Media.propTypes = {
    tag: PropTypes.string
};

export default translate(Media);