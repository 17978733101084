'use strict';

import React from 'react';
import AOS from "aos";
import swal from 'sweetalert';
import Parse from 'parse';
import $ from "jquery";
import _ from 'lodash';

import translate from "../translate";

const isValidEmail = require('is-valid-email');
import {openChat} from '../../lib/ui-utils';
import paths from "../../lib/paths";

class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                name: '',
                email: '',
                subject: '',
                comment: ''
            },
            errors: {
                name: false,
                email: false,
                subject: false,
            },
            faqActiveCategoryId: 'All',
            faqCategories: [],
            faqList: []
        }
        this.contactFormRef = React.createRef();
        this.sendForm = this.sendForm.bind(this);
        this.getContactFAQs = this.getContactFAQs.bind(this);
    }

    async componentDidMount() {
        AOS.init({
            duration: 1500,
            delay: 1000
        });

        const mapLoader = new google.maps.plugins.loader.Loader({
            apiKey: 'AIzaSyB96M1pFraIHQu5IWLR-yalbwXtIGY0K2g',
            version: "weekly",
        })
        mapLoader.load().then(() => {
            const mapElement = document.getElementById('map-section');
            const options = {
                center: {lat: 46.960541, lng: 7.513081},
                zoom: 13,
            };

            const googleMap = new google.maps.Map(mapElement, options);
            const marker = new google.maps.Marker({
                position: {lat: 46.960541, lng: 7.513081},
                map: googleMap
            });
            const infoWindow = new google.maps.InfoWindow({
                content: '<h5>Cleveron AG</h5><p>Bernapark 28<br/>3066 Stettlen</p> <a href="https://www.google.ch/maps/place/CLEVERON/@46.9605409,7.510896,17z/data=!3m1!4b1!4m5!3m4!1s0x478e37b2f518d8e7:0xee702a298225e820!8m2!3d46.9605409!4d7.5130847" target="_blank">Show in Google Maps</a>'
            });

            infoWindow.open(googleMap, marker);

            marker.addListener('click', function () {
                infoWindow.open(googleMap, marker);
            });

        })

        document.getElementById('submit-form').onSubmit = function() {
            return this.sendForm();
        };

        await this.getContactFAQs();
    }

    async getContactFAQs(){
        let response = await Parse.Cloud.run('creator-business-website-report-contact-faq', {refresh: true});
        let faqList = response?.contactFAQs?.filter(faqItem => faqItem.public === 'Public') ?? [];
        let faqCategories = _.uniq(
            faqList
                .map(faqItem => faqItem.category)
                .filter(category => !!category)
        );
        faqCategories.unshift('All');
        this.setState({faqList, faqCategories});
    }

    inputValues(event, field) {
        let value = event.target.value;

        this.setState(prev => {
            prev.form[field] = value;

            prev.errors[field] = false;

            return prev;
        });
    }

    async sendForm(e) {
        const {form: {email, comment, name, subject}} = this.state;
        const {t} = this.props;

        this.setState({
            form:{...this.state.form},
            errors: {
                name: !name,
                email: !email,
                isValidEmail: !isValidEmail(email)
            }
        });

        if(!(name && email && isValidEmail(email))) {
            e.preventDefault();
            return
        }

        try {
            let token = await grecaptcha.execute('6LcHZqUZAAAAAAB9FgyLwilIjZAk8YRT23Uo0OH4', {action: 'submit'});

            /*await request
                 .post('/api/send-email?subject=home-contact-form')
                 .send(_.merge({}, {mail: email, name, comment}, {token})) // sends a JSON post body
                 .set('accept', 'json');*/

            await Parse.Cloud.run('send-email', {
                mail: email,
                name,
                subject,
                comment,
                token
            });

            this.setState({
                form: {
                    name: '',
                    email: '',
                    subject: '',
                    comment: ''
                },
                errors: {
                    name: false,
                    email: false,
                    subject: false,
                }
            });

            swal(t( 'Thank you!'), t('A contact request has been sent, a specialist will be in touch via email soon.'), 'success');
        } catch (e) {
            console.error(e.message);
            swal('Error', e.message, 'error');
        }
    }


    openDescriptionBlock(id) {
        const element = document.getElementById(id);

        if(element.classList.contains('active')) {
            element.classList.remove('active')
        } else {
            element.classList.add('active')
        }
    }

    render() {
        const {errors, form: {name, email, subject, comment}} = this.state;
        const {t} = this.props;

        let language = this.props.i18n.resolvedLanguage;

        let instagramPosts = [
            {
                imageUrl: 'https://ik.imagekit.io/sh/cleveron_ch/1_e_ToxYHKV.jpeg?updatedAt=1634908033569',
                linkInstagram: 'https://www.instagram.com/cleveronag/'
            },
            {
                imageUrl: 'https://ik.imagekit.io/sh/cleveron_ch/2_J8Hv5U6j_.jpeg?updatedAt=1634908033458',
                linkInstagram: 'https://www.instagram.com/cleveronag/'
            },
            {
                imageUrl: 'https://ik.imagekit.io/sh/cleveron_ch/3_umUkqFWEM.jpeg?updatedAt=1634908033183',
                linkInstagram: 'https://www.instagram.com/cleveronag/'
            },
            {
                imageUrl: 'https://ik.imagekit.io/sh/cleveron_ch/4_RfkfotkCR.jpeg?updatedAt=1634908024322',
                linkInstagram: 'https://www.instagram.com/cleveronag/'
            }
        ];

        return (
            <main className="main">
                <section className="basic-section contact-section">
                    <div className="basic-section__row">
                        <div className="text-block" style={{paddingBottom: 0}}>
                            <div className="container">
                                <div>
                                    <h3 style={{borderBottom: "1px solid #040405", paddingBottom: "10px"}}>
                                        {t('page-5-label-14')}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="basic-section">
                    <div className='contact-section-problem-type'>
                        <div className='contact-section-problem-type-item'>
                            <a id='having-issue-with-device'  onClick={(e) => {
                                window.location.href = 'mailto:suppport@cleveron.ch';
                                e.preventDefault();
                            }}>{t('page-5-label-15')}</a>
                        </div>
                        <div className='contact-section-problem-type-item'>
                            <a onClick={(e) => {
                                window.location.href = 'mailto:info@cleveron.ch';
                                e.preventDefault();
                            }}>{t('page-5-label-16')}</a>
                        </div>
                    </div>
                </section>
                <section className='contact-section-faq' data-aos='fade-left'  ref={this.contactFormRef}>
                    <div className='wrapper'>
                        <h3 className='contact-section-faq-title'>{t('page-5-label-29')}</h3>
                        <div className='contact-section-faq-category'>
                            {this.state.faqCategories.map((category) =>
                                <div key={category} className={`contact-section-faq-category-item ${this.state.faqActiveCategoryId === category ? 'active' : ''}`}
                                     onClick={() => this.setState({faqActiveCategoryId: category})}>
                                    {t(`component-13-label-${category}`)}
                            </div>)}
                        </div>
                        <ul className='faq__list'>
                            {this.state.faqList.filter(faq => faq.category === this.state.faqActiveCategoryId || this.state.faqActiveCategoryId === 'All')
                                .map(faq => {
                                    let title = faq[`title_${language}`];
                                    let descriptionHtml = faq[`description_html_${language}`];
                                    let description = faq[`description_${language}`];

                                    return <li id={faq.ID}  key={faq.ID} className='faq__item'>
                                        <h3 className='faq__quastion'>{title}</h3>
                                        {
                                            descriptionHtml != '' &&
                                            descriptionHtml != null &&
                                            <div className='faq__answer' dangerouslySetInnerHTML={{__html:descriptionHtml}}></div>
                                        }
                                        {
                                            (descriptionHtml === '' || descriptionHtml == null)  &&
                                            <div className='faq__answer'>{description}</div>
                                        }
                                        <div className='faq__open-btn' onClick={() => this.openDescriptionBlock(faq.ID)}>
                                            <span className='faq__btn-line' />
                                        </div>
                                    </li>
                                })}
                        </ul>
                    </div>
                </section>
                <section className="basic-section contact-section">
                    <div className="basic-section__row">
                        <div className="text-block">
                            <div className="container">
                                <div>
                                    <h3>
                                        {t('page-5-label-0')}
                                        <img className='img img--big'    style={{marginTop: 10}} loading={'lazy'} src='https://ik.imagekit.io/sh/cleveron_ch/team_3_SmPhvNXs2.jpg?tr=w-700' alt='' width={'50%'}/>
                                        <img className='img img--medium' loading={'lazy'} src='https://ik.imagekit.io/sh/cleveron_ch/team_3_SmPhvNXs2.jpg?tr=w-700' alt='' width={'50%'}/>
                                        <img className='img img--small'  loading={'lazy'} src='https://ik.imagekit.io/sh/cleveron_ch/team_3_SmPhvNXs2.jpg?tr=w-700' alt='' width={'50%'}/>
                                    </h3>
                                </div>
                                <div className="basic-section__row">
                                    <div>
                                        <div className="text-block__info">
                                            <h5 className="contact-section__title">{t('page-5-label-1')}</h5>
                                            <a href="mailto:info@cleveron.ch">info@cleveron.ch</a>
                                        </div>
                                        <div className="text-block__info">
                                            <h5 className="contact-section__title">{t('page-5-label-2')}</h5>
                                            <a href="tel:+41 (0)31 539 11 23">+41 (0)31 539 11 23</a>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="text-block__info">
                                            <h5 className="contact-section__title">{t('page-5-label-3')}</h5>
                                            Cleveron AG
                                            <br />
                                            Bernapark  28
                                            <br />
                                            3066 Stettlen
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-block">
                            <div className="container">
                                <form
                                    id='submit-form'
                                    onSubmit={this.sendForm}
                                    className="contact-form form"
                                    ref={this.contactFormRef}
                                >
                                    <fieldset>
                                        <label className={`form__label ${errors.name ? 'error' : ''}`} data-label="Name" data-error={t('page-5-label-4')}>
                                            <input
                                                name="name"
                                                id="name"
                                                type="text"
                                                className="form__input"
                                                placeholder={t('page-5-label-5')}
                                                onChange={(e) => this.inputValues(e, 'name')}
                                                value={name}
                                            />
                                        </label>
                                        <label className={`form__label ${errors.email || errors.isValidEmail ? 'error' : ''}`} data-label={t('page-5-label-6')}
                                               data-error={t('page-5-label-7')}>
                                            <input
                                                name="mail"
                                                id="mail"
                                                type="email"
                                                className="form__input"
                                                placeholder={t('page-5-label-8')}
                                                value={email}
                                                onChange={(e) => this.inputValues(e, 'email')}
                                            />
                                        </label>
                                        <label className="form__label" data-label={t('page-5-label-9')}
                                               data-error="">
                                            <input
                                                type="text"
                                                name="subject"
                                                id="subject"
                                                className="form__input"
                                                placeholder={t('page-5-label-10')}
                                                value={subject}
                                                onChange={(e) => this.inputValues(e, 'subject')}
                                            />
                                        </label>
                                        <label className="form__label" data-label="">
                                            <textarea
                                                name="comment"
                                                id="comment"
                                                cols="30"
                                                rows="10"
                                                value={comment}
                                                placeholder={t('page-5-label-11')}
                                                onChange={(e) => this.inputValues(e, 'comment')}
                                            />
                                        </label>
                                        <label className="form__submit">
                                            <input type="button" onClick={this.sendForm}/>
                                            <span className="btn">{t('page-5-label-12')}</span>
                                        </label>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="map-section" id="map-section">
                </section>
                <section className="social-section">
                    <div className="wrapper">
                        <div className="social-section__heading">
                            <h3>{t('page-5-label-13')}</h3>
                            <div className="social">
                                <div className="social__box">
                                    <a href="https://www.linkedin.com/company/cleveron-ag/" target={'_blank'} className="social__link">
                                        <svg className="social__img" xmlns="http://www.w3.org/2000/svg" width="20.965"
                                             height="20.039"
                                             viewBox="0 0 20.965 20.039">
                                            <g id="linkedin" transform="translate(0 0.668)">
                                                <path className="fill"
                                                      d="M2.542-.668A2.342,2.342,0,1,0,2.483,4h.029a2.343,2.343,0,1,0,.03-4.672Zm0,0"/>
                                                <path className="fill" d="M8.109,198.313H12.6v13.521H8.109Zm0,0"
                                                      transform="translate(-7.844 -192.462)"/>
                                                <path className="fill"
                                                      d="M229.759,188.625a5.63,5.63,0,0,0-4.05,2.278v-1.961h-4.494v13.521h4.494v-7.551a3.08,3.08,0,0,1,.148-1.1,2.46,2.46,0,0,1,2.306-1.643c1.626,0,2.277,1.24,2.277,3.058v7.233h4.494v-7.753C234.933,190.558,232.716,188.625,229.759,188.625Zm0,0"
                                                      transform="translate(-213.968 -183.092)"/>
                                            </g>
                                        </svg>
                                    </a>
                                    <a href="https://www.instagram.com/cleveronag/" target="_blank" className="social__link">
                                        <svg className="social__img" id="instagram" xmlns="http://www.w3.org/2000/svg"
                                             width="26.187" height="26.187"
                                             viewBox="0 0 26.187 26.187">
                                            <path className="fill" id="Mask-2" data-name="Mask"
                                                  d="M18.961,26.187H7.226A7.235,7.235,0,0,1,0,18.961V7.226A7.235,7.235,0,0,1,7.226,0H18.961a7.235,7.235,0,0,1,7.226,7.226V18.961A7.235,7.235,0,0,1,18.961,26.187ZM7.226,2.323a4.909,4.909,0,0,0-4.9,4.9V18.961a4.909,4.909,0,0,0,4.9,4.9H18.961a4.909,4.909,0,0,0,4.9-4.9V7.226a4.909,4.909,0,0,0-4.9-4.9Zm5.867,17.629a6.858,6.858,0,1,1,6.858-6.858A6.866,6.866,0,0,1,13.094,19.952Zm0-11.356a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,13.094,8.6Z"
                                                  fill="#212123"/>
                                        </svg>
                                    </a>
                                    <a href="https://www.facebook.com/CleveronAg/?ref=br_rs" target="_blank" className="social__link">
                                        <svg className="social__img" id="facebook" xmlns="http://www.w3.org/2000/svg"
                                             width="12.323" height="23.277"
                                             viewBox="0 0 12.323 23.277">
                                            <path className="fill" id="Facebook-2" data-name="Facebook"
                                                  d="M8,23.277V12.66h3.632l.545-4.139H8V5.879c0-1.2.338-2.014,2.091-2.014h2.233V.162A30.858,30.858,0,0,0,9.069,0C5.848,0,3.643,1.928,3.643,5.469V8.521H0V12.66H3.643V23.277Z"/>
                                        </svg>
                                    </a>
                                    <a href="https://twitter.com/@CleveronAg" target="_blank" className="social__link">
                                        <svg className="social__img" id="twitter" xmlns="http://www.w3.org/2000/svg"
                                             width="24.732" height="20.09"
                                             viewBox="0 0 24.732 20.09">
                                            <path className="fill" id="Shape-2" data-name="Shape"
                                                  d="M24.732,2.377a10.132,10.132,0,0,1-2.913.8A5.1,5.1,0,0,0,24.049.371,10.228,10.228,0,0,1,20.825,1.6,5.078,5.078,0,0,0,12.18,6.228,14.4,14.4,0,0,1,1.723.927,5.079,5.079,0,0,0,3.293,7.7,5.074,5.074,0,0,1,1,7.063v.063A5.079,5.079,0,0,0,5.064,12.1a5.139,5.139,0,0,1-1.337.178,4.87,4.87,0,0,1-.955-.094,5.076,5.076,0,0,0,4.738,3.524,10.179,10.179,0,0,1-6.3,2.168A10.783,10.783,0,0,1,0,17.807,14.341,14.341,0,0,0,7.777,20.09,14.335,14.335,0,0,0,22.212,5.655L22.195,5A10.132,10.132,0,0,0,24.732,2.377Z"/>
                                        </svg>
                                    </a>
                                    <a href="https://www.youtube.com/channel/UCT0-SxAVWOpZz0VM91MGXqw" className="social__link" target={'_blank'}>
                                        <svg className="social__img" aria-hidden="true" focusable="false"
                                             data-prefix="fab" data-icon="youtube"
                                             className="svg-inline--fa fa-youtube fa-w-18" role="img"
                                             xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 576 512">
                                            <path className="fill"
                                                  d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z">
                                            </path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="social-section__row">
                            {
                                instagramPosts.map((instagramPost, i) => {
                                    return <div key={i} className="social-section__item">
                                        <a href={instagramPost.linkInstagram} target={'_blank'} className="social-section__link" data-aos="flip-down">
                                            <img src={instagramPost.imageUrl} loading={'lazy'} alt="" className="social-section__img" />
                                        </a>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

export default translate(Contact);