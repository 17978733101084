'use strict';

// import modules here
import "regenerator-runtime/runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import {Router, Route, Switch} from 'react-router-dom';
import es6Promise from 'es6-promise';
import {initializeParse} from './lib/parse-utils';
import * as paths from './lib/paths';
import {isMobile} from './lib/helpers';
import $ from 'jquery';
import './scss/main.scss';

initializeParse();

es6Promise.polyfill();

// import components here
import NotFound from './components/not-found';
import ErrorPage from './components/page-error';
import Footer from './components/footer';
import Header from './components/header';
import Home from './components/home'
import FullSystem from './components/fullSystem'
import About from './components/about'
import Products from './components/products'
import AirQuality from './components/air-quality'
import AboutProject from './components/about-project'
import Testimonials from './components/testimonials'
import CaseStudy from './components/case-study'
import CaseStudyOpen from './components/case-study-open'
import Media from './components/media'
import News from './components/news'
import Video from './components/video'
import Calculator from './components/caclulator/index'
import Contact from './components/contact'
import Partnership from './components/partnership'

import createBrowserHistory from 'history/createBrowserHistory';
import CookiePrivacyPopup from "./components/cookies-privacy-popup";
import PageAgb from "./components/page-agb";
import PageDataProtection from "./components/page-data-protection";
import PageImpressum from "./components/page-impressum";

const customHistory = createBrowserHistory();

customHistory.listen((location, action) => {
    window.scrollTo(0, 0);
});

if (document.getElementById('app')) {
    ReactDOM.render(
        (
                <>
                    <Header history={customHistory} />
                    <Router history={customHistory}>
                        <Switch>
                            <Route exact path={paths.home} component={Home} />
                            <Route exact path={paths.about} component={About} />
                            <Route exact path={paths._old.team} component={About}/>
                            <Route exact path={paths.heating} component={FullSystem} />
                            <Route exact path={paths.products} component={Products} />
                            <Route exact path={paths.air} component={AirQuality} />
                            <Route exact path={paths.aboutProject} component={AboutProject} />
                            <Route exact path={paths.testimonials} component={Testimonials} />
                            <Route exact path={paths.caseStudy} component={CaseStudy} />
                            <Route exact path={paths._old.references} component={CaseStudy} />
                            <Route exact path={paths.media} component={Media} />
                            <Route exact path={paths._old.blog} component={Media} />
                            <Route exact path={paths.news} component={News} />
                            <Route exact path={paths.mediaTAg} component={Media} />
                            <Route exact path={paths.video} component={Video} />
                            <Route exact path={paths.calculator} component={Calculator} />
                            <Route exact path={paths._old.pricing} component={Calculator} />
                            <Route exact path={paths.demoRequest} component={Calculator} />
                            <Route exact path={paths.contact} component={Contact} />
                            <Route exact path={paths.openCaseStudy} component={CaseStudyOpen} />
                            <Route exact path={paths.partnership} component={Partnership} />
                            <Route exact path={paths._old.campaign} component={Partnership} />
                            <Route exact path={paths.agb} component={PageAgb} />
                            <Route exact path={paths.agb} component={PageDataProtection} />
                            <Route exact path={paths.impressum} component={PageImpressum} />
                            <Route exact path={paths.error} component={ErrorPage}/>
                            <Route exact component={NotFound}/>
                        </Switch>
                    </Router>
                    <Footer />
                    <CookiePrivacyPopup/>
                </>
        ),
        document.getElementById('app')
    );
}


let body = document.querySelector('body');
if (isMobile.any()) {
    body.classList.add('touch');
} else {
    body.classList.add('mouse');
}


