'use strict';

import React from 'react';
import AOS from "aos";
import axios from 'axios';
import _ from 'lodash';
import Parse from 'parse';

import translate from "../translate";
import ContactCTA from "../contactCTA";
import db from "../../lib/structure";

import fabrica from '../../assets/icon/case-study/fabrica.svg';
import schema from '../../assets/icon/product/schema.svg';
import filter from '../../assets/icon/testimonials/filter.svg';
import airQuality from '../../assets/icon/product/air-quality.svg';
import cloud from '../../assets/icon/testimonials/cloud.svg';

class CaseStudyOpen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.loadCaseStudy = this.loadCaseStudy.bind(this);

    }

    componentDidMount() {
        AOS.init({
            duration: 1500,
            delay: 1000
        });

        this.loadCaseStudy();
    }

    async loadCaseStudy(){
        let response = await Parse.Cloud.run('crm-case-study', {
            caseStudyId: this.props.match.params.id,
            ...Object.fromEntries(new URLSearchParams(this.props.history.location.search))
        });
        this.setState({caseStudy: response});
    }

    render() {
        const {t} = this.props;

        console.log(this.state.caseStudy);

        if(!this.state.caseStudy) return '';

        return (
            <main className="main">
                <section className="title-section first-screen">
                    <div className="wrapper">
                        <div className="title-section__main-info">
                            {
                                this.state.caseStudy[db.ZohoBuilding.CASE_STUDY_TESTIMONIAL_TEXT.de] && <h2>
                                    {
                                        this.state.caseStudy[db.ZohoBuilding.CASE_STUDY_TESTIMONIAL_TEXT.de]
                                            .split(' ')
                                            .map((word, i) => {
                                                if(word.startsWith('$'))
                                                    return <span key={i} className='decor-color' data-aos='fade' data-aos-delay={(i+1) * 50}>{word.replace('$', '')} </span>
                                                return <span key={i} data-aos='fade' data-aos-delay={(i+1) * 50}>{word} </span>
                                            })
                                    }
                                </h2>
                            }
                            {
                                !this.state.caseStudy[db.ZohoBuilding.CASE_STUDY_TESTIMONIAL_TEXT.de] && <h2>
                                    {this.state.caseStudy[db.ZohoBuilding.NAME]}
                                </h2>
                            }
                        </div>
                    </div>
                </section>
                <section className="sidebar-section" style={{padding: 0}}>
                    <div className="wrapper">
                        <div className="sidebar-section__row">
                            <div className="sidebar-section__sidebar">
                                <a href={this.state.caseStudy[db.ZohoBuilding.CASE_STUDY_CUSTOMER_WEBSITE]} target={'_blank'}>
                                    <img className="sidebar-section__client-logo" loading={'lazy'} src={this.state.caseStudy[db.ZohoBuilding.CASE_STUDY_LOGO_IMAGE]}
                                         alt="" />
                                </a>
                            </div>
                            {
                                _.get(this.state.caseStudy, db.ZohoBuilding.CASE_STUDY_TESTIMONIAL_NAME.de) &&<div
                                    className="sidebar-section__content">
                                    <div className="sidebar-section__row">
                                        <div className="sidebar-section__col sidebar-section__col--6">
                                        <span>
                                             {_.get(this.state.caseStudy, db.ZohoBuilding.CASE_STUDY_TESTIMONIAL_NAME.de)} - &nbsp;
                                            {_.get(this.state.caseStudy, db.ZohoBuilding.CASE_STUDY_TESTIMONIAL_POSITION.de)}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </section>
                <section className="big-img" data-aos="zoom-out">
                    <div className="big-img__img-block">
                        <img className="img img--big img-object-fit"    style={{height: 600}} loading={'lazy'} src={`${this.state.caseStudy[db.ZohoBuilding.CASE_STUDY_MAIN_IMAGE]}?tr=w-1600`} alt="" />
                        <img className="img img--medium img-object-fit" style={{height: 600}} loading={'lazy'} src={`${this.state.caseStudy[db.ZohoBuilding.CASE_STUDY_MAIN_IMAGE]}?tr=w-1400`} alt="" />
                        <img className="img img--small img-object-contain"                    loading={'lazy'} src={`${this.state.caseStudy[db.ZohoBuilding.CASE_STUDY_MAIN_IMAGE]}?tr=w-700`} alt="" />
                    </div>
                </section>
                <section className="sidebar-section">
                    <div className="wrapper">
                        <div className="sidebar-section__row">
                            <div className="sidebar-section__sidebar">
                                <img src={fabrica} loading={'lazy'} alt="" className="text-section__icon" />
                                    <br />
                                        <p>{this.state.caseStudy[db.ZohoBuilding.NAME]}</p>
                            </div>
                            <div className="sidebar-section__content">
                                <div className="sidebar-section__row">
                                    <div className="sidebar-section__col">
                                        <h3>{_.get(this.state.caseStudy, db.ZohoBuilding.CASE_STUDY_TITLE.de)}</h3>
                                        <p>
                                            {_.get(this.state.caseStudy, db.ZohoBuilding.CASE_STUDY_DESCRIPTION.de)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="text-section">
                    <div className="wrapper">
                        <div className="text-section__row text-section__row--col-3">
                            <div className="text-section__item text-section__item--icon">
                                <h3 data-aos="fade-left" data-aos-offset='-10' data-aos-delay="50">
                                    {t('page-7-label-1')}
                                </h3>
                            </div>
                            {
                                this.state.caseStudy[db.ZohoBuilding.CASE_STUDY_SURFACE] && <div
                                    className="text-section__item text-section__item--icon">
                                    <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                        <img src={schema} loading={'lazy'} alt="" className="text-section__icon" />
                                    </div>
                                    <div className="text-section__info-box">
                                        <div className="h3">{this.state.caseStudy[db.ZohoBuilding.CASE_STUDY_SURFACE]}</div>
                                        <div className="text-section__info">
                                            {t('page-7-label-2')}
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.caseStudy[db.ZohoBuilding.CASE_STUDY_THERMOSTATS] && <div
                                    className="text-section__item text-section__item--icon">
                                    <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                        <img src={filter} loading={'lazy'} alt=""
                                             className="text-section__icon" />
                                    </div>
                                    <div className="text-section__info-box">
                                        <div className="h3">{this.state.caseStudy[db.ZohoBuilding.CASE_STUDY_THERMOSTATS]}</div>
                                        <div className="text-section__info">
                                            {t('page-7-label-3')}
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.caseStudy[db.ZohoBuilding.CASE_STUDY_SENSORS] && <div
                                    className="text-section__item text-section__item--icon">
                                    <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                        <img src={airQuality} loading={'lazy'} alt=""
                                             className="text-section__icon" />
                                    </div>
                                    <div className="text-section__info-box">
                                        <div className="h3">{this.state.caseStudy[db.ZohoBuilding.CASE_STUDY_SENSORS]}</div>
                                        <div className="text-section__info">
                                            {t('page-7-label-4')}
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.caseStudy[db.ZohoBuilding.CASE_STUDY_EMISSON_SAVED] && <div
                                    className="text-section__item text-section__item--icon">
                                    <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                        <img src={cloud} loading={'lazy'} alt=""
                                             className="text-section__icon" />
                                    </div>
                                    <div className="text-section__info-box">
                                        <div className="h3">{this.state.caseStudy[db.ZohoBuilding.CASE_STUDY_EMISSON_SAVED]}</div>
                                        <div className="text-section__info">
                                            {t('page-7-label-5')}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </section>
                <ContactCTA />
            </main>
        );
    }
}

export default translate(CaseStudyOpen);

