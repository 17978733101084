'use strict'

import React from 'react';

import translate from "../translate";

class PageAgb extends React.Component{
    constructor(props) {
        super(props);
    }

    openDescriptionBlock(id) {
        const element = document.getElementById(id);

        if(element.classList.contains('active')) {
            element.classList.remove('active')
        } else {
            element.classList.add('active')
        }
    }

    render() {
        const {t} = this.props;

        let generalTermsAndConditionsTexts = [
            {
                title: `1. Geltungsbereich, Begriffsbestimmungen, Änderungsbefugnis`,
                text: `
                    Für die Geschäftsbeziehung zwischen dem Anbieter und dem Kunden (nachfolgend „Kunde“) gelten ausschliesslich die nachfolgenden Allgemeinen Geschäftsbedingungen in ihrer zum Zeitpunkt des Vertragsschlusses gültigen Fassung. 
     
                    Sofern in diesen allgemeinen Geschäftsbedingungen von "Verbrauchern" die Rede ist, sind dies natürliche Personen, bei denen der Zweck der Bestellung nicht einer gewerblichen, selbständigen oder freiberuflichen Tätigkeit zugerechnet werden kann. "Unternehmer" sind dagegen natürliche oder juristische Personen oder rechtsfähige Personengesellschaften, die zu gewerblichen, selbständigen oder freiberuflichen Zwecken bestellen. "Kunden" im Sinne dieser allgemeinen Geschäftsbedingungen sind sowohl Verbraucher als auch Unternehmer. 
                     
                    Werden als Fristen Werktage angegeben, so verstehen sich darunter alle Wochentage mit Ausnahme von Samstagen, Sonntagen und gesetzlichen Feiertagen.
                `
            },
            {
                title: `2. Vertragsgegenstand`,
                text: `
                Gegenstand des Vertrages ist die Nutzung der Software-Applikationen und Endgeräte des Anbieters, die ein intelligentes Heizungssystem ermöglichen. Applikationen des Anbieters - nachfolgend “Apps” - umfassen sowohl webbasierte Applikationen als auch mobile Applikationen. 
 
Die mobilen Apps werden über die unterschiedlichen Stores für mobile Applikationen (iOS App-Store, Android Play Store, Windows Store etc.) bereitgestellt. Die Web-Applikation ist über die Webseite des Anbieters abrufbar.
                `
            },
            {
                title: `3. Vertragsschluss`,
                text: `
                Alle Angebote des Anbieters sind freibleibend und unverbindlich. 
 
Bei einem Kauf oder der Miete von Endgeräten vom Anbieter kommt der Vertrag grundsätzlich erst durch die Abgabe der Annahmeerklärung durch den Anbieter zustande, die bei telefonischer Bestellung, einer Bestellung über den Webshop oder sonstigem Bestellweg mit einer gesonderten E-Mail oder schriftlichen Brief (Auftragsbestätigung) versandt wird. 
 
Unabhängig von der Annahme des Vertrages durch eine Auftragsbestätigung per E-Mail, kommt bei einer Bestellung beim Anbieter ein verbindlicher Vertrag spätestens mit der Lieferung der Endgeräte zustande. 
 
Bei einem Erwerb von Endgeräten des Anbieters über Dritte kommt ein Vertragsverhältnis zwischen dem Anbieter und dem Kunden erst mit der Registrierung beim Anbieter durch den Kunden über eine der Apps des Anbieters zustande.
                `
            },
            {
                title: `4. Allgemeine Pflichten des Kunden`,
                text: `
                Der Kunde sichert zu, dass die dem Anbieter bei der Bestellung und der Registrierung mitgeteilten Daten richtig und vollständig sind. Er verpflichtet sich, den Anbieter jeweils unverzüglich über Änderungen der mitgeteilten Daten zu unterrichten und auf entsprechende Anfrage des Anbieters binnen 14 Tagen ab Zugang die aktuelle Richtigkeit erneut zu bestätigen. Dieses betrifft insbesondere Name, postalische Anschrift, E-Mail-Adresse sowie Telefonnummer des Kunden, ggf. seine Rechtsform und Details zur gewählten Zahlungsmethode.
                `
            },
            {
                title: `5. Preise und Zahlungsbedingungen`,
                text: `
                Sofern keine individuelle Offerte des Anbieters vorliegt, gelten die auf den Internetseiten des Anbieters dargestellten Preise zum Zeitpunkt der Bestellung. Sofern Versandkosten anfallen, informiert der Anbieter über deren Höhe im Bestellprozess. 
 
Der Anbieter behält sich vor, für die verschiedenen Zahlungsoptionen Dritte mit der Abwicklung zu beauftragen. 
 
Rechnungen werden dem Kunden grundsätzlich per Email oder Post zugesandt. Der Rechnungsbetrag ist spätestens 30 Tage nach Erhalt der Rechnung fällig. 
 
Gerät der Kunde mit einer Zahlung in Verzug, ist der Anbieter berechtigt, eine Mahnpauschale pro Mahnung zu erheben. 
 
Für jede nicht eingelöste bzw. zurückgereichte Lastschrift oder Kreditkartenzahlung ist der Anbieter berechtigt, eine Pauschale in Rechnung zu stellen, es sei denn, der Kunde weist nach, dass er das Schaden auslösende Ereignis nicht zu vertreten hat. Dem Kunden bleibt der Nachweis gestattet, dass kein oder ein geringerer Schaden als die Pauschale entstanden ist.
                `
            },
            {
                title: `6. Lieferung und Eigentumsvorbehalt`,
                text: `
                Lieferungen erfolgen ausschliesslich an Lieferadressen in der Schweiz und EU.
 
Der Kunde ist verantwortlich dafür, dass unter der Lieferadresse zu den üblichen Geschäftszeiten zugestellt werden kann. Die Lieferung erfolgt in der Regel innerhalb von vier Wochen nach Annahme des Antrags des Kunden auf Abschluss des Vertrages. 
 
Der Anbieter behält sich vor, vom Vertrag zurückzutreten, sollte die Zustellung aufgrund eines Verschuldens des Kunden dreimal scheitern. Bereits vom Kunden geleistete Zahlungen werden in diesem Fall vom Anbieter erstattet. 
 
Werden Endgeräte mit offensichtlichen Schäden an der Verpackung oder am Inhalt angeliefert, so hat der Kunde dies unbeschadet seiner Gewährleistungsrechte dem Anbieter spätestens zwei Wochen nach Erhalt der Endgeräte mitzuteilen, damit der Anbieter den Transportschaden gegenüber dem beauftragten Logistikunternehmen geltend machen kann. 
 
Grundsätzlich trägt der Kunde im Falle einer Rücksendung die Kosten der Rücksendung. Ausgenommen hiervon ist die Rücksendung beim Widerruf. 
 
Kauft der Kunde Endgeräte, verbleiben diese bis zur vollständigen Bezahlung durch den Kunden im Eigentum des Anbieters. 
 
Mietet der Kunde Endgeräte, so bleiben diese im Eigentum des Anbieters. Nach Beendigung des Vertragsverhältnisses hat der Kunde die Endgeräte auf seine Kosten und auf seine Gefahr an den Anbieter zurückzusenden.
                `
            },
            {
                title: `7. Widerrufsrecht`,
                text: `
                Schliesst der Kunde als Verbraucher einen Vertrag mit dem Anbieter und verwenden der Kunde und der Anbieter für die Vertragsverhandlungen und den Vertragsschluss ausschliesslich Fernkommunikationsmittel (z. B. Bestellung über den Webshop des Anbieters sowie Telefon oder Email), steht dem Kunden in der Regel ein Widerrufsrecht zu. 
 
Die Widerrufsfrist bei der Lieferung von Endgeräten beträgt 14 Tage ab dem Tag, an dem der Kunde oder ein von ihm benannter Dritter, der nicht der Beförderer ist, die Endgeräte in Besitz genommen hat. Wurden mehrere Endgeräte im Rahmen einer einheitlichen Bestellung bestellt und werden diese getrennt geliefert, beträgt die Widerrufsfrist vierzehn Tage ab dem Tag, an dem der Kunde oder ein von ihm benannter Dritter, der nicht der Beförderer ist, das letzte Endgerät in Besitz genommen hat. 
 
Der Anbieter trägt die Kosten der Rücksendung nur, wenn das vom Anbieter bereitgestellte Retourenetikett verwendet wird. Bei anderen Rücksendungsarten werden die Rücksendungskosten nicht erstattet. Eine Verpflichtung zur Bereitstellung eines Retourenetiketts durch den Anbieter besteht nicht. 
 
Die Widerrufsfrist für den Installationsservice des Anbieters (vgl. Ziffer 9) beträgt 14 Tage ab dem Tag des Vertragsabschlusses. Das Widerrufsrecht erlischt regelmässig, wenn die Installationsdienstleistung vollständig erbracht wurde. 
 
Die vollständige Belehrung durch den Anbieter erfolgt gesondert im Anschluss an diese allgemeinen Geschäftsbedingungen.
                `
            },
            {
                title: `8. Vertragslaufzeit, Kündigung und Wechsel zum Kauf`,
                text: `
                Der Vertrag über die Miete der Endgeräte des Anbieters wird für 60 Monate abgeschlossen, wenn nicht anderes vereinbart wurde und verlängert sich automatisch um jeweils weitere 12 Monate, wenn er nicht mit einer Frist von 90 Tagen vor Ablauf der Vertragslaufzeit gekündigt wird. Das Recht zur ausserordentlichen Kündigung aus wichtigem Grund bleibt unberührt.
 
Die Kündigung muss schriftlich erfolgen. Erfolgt die Kündigung des Vertrags, ist der Kunde verpflichtet, die Endgeräte in vollem Umfang an den Anbieter zurückzusenden. 
 
Sind die Endgeräte innerhalb von 30 Tagen nach Beendigung des Vertragsverhältnisses nicht, beschädigt oder unvollständig beim Anbieter eingegangen, ist der Anbieter berechtigt, die Endgeräte zum Preis von CHF 60.- pro Gerät in Rechnung zu stellen. Sind die Endgeräte innerhalb von 30 Tagen nach Beendigung des Vertragsverhältnisses nicht beim Anbieter eingegangen, ist der Anbieter berechtigt, die Endgeräte basiert auf der Nutzungsdauer in Rechnung zu stellen. Die Funktionalitäten dieser Geräte können nach der Kündigung vom Anbieter deaktiviert werden. 
                `
            },
            {
                title: `9. Installation & Technische Voraussetzungen`,
                text: `
                Die Nutzung der Applikationen des Anbieters mit vollem Funktionsumfang setzt eine funktionierende und fortlaufend bestehende Internetverbindung am Installationsort voraus. Für eine vollumfängliche Nutzung der mobilen Applikationen wird weiterhin eine mobile Datenverbindung benötigt. Die Bereitstellung und Erhaltung der Funktionsfähigkeit der Internetverbindungen obliegt dem Kunden. 
 
Anhand der Kundenangaben bei der Bestellung nimmt der Anbieter eine Kompatibilitätsprüfung des bestehenden Systems vor. Sollte die Kompatibilität nicht gewährleistet sein, informiert der Anbieter den Kunden unverzüglich darüber. Der Kunde ist daraufhin verpflichtet, innerhalb der Fristen zum Widerruf die entsprechenden Erklärungen abzugeben und allfällige bereits versandte Endgeräte vollständig und ohne Gebrauchsspuren an den Anbieter zurückzusenden. 
 
Die Installation und der Rückbau der Endgeräte des Anbieters zu Beginn und nach Beendigung des Vertragsverhältnisses gehören regelmässig nicht zum vertraglichen Leistungsumfang. 
                `
            },
            {
                title: `10. Datenschutz`,
                text: `
                 
Bei allen Vorgängen der Datenverarbeitung (z.B. Erhebung, Verarbeitung und Übermittlung) handelt der Anbieter nach den gesetzlichen Vorschriften. Die vom Kunden übermittelten personenbezogenen Daten, Bestellungen und Betriebsdaten der Endgeräte werden beim Anbieter elektronisch gespeichert. 
 
Weitere Informationen über Art, Umfang, Ort und Zweck der Erhebung, Verarbeitung und Nutzung der für die Ausführung von Bestellungen notwendigen personenbezogenen Daten befinden sich in der Datenschutzerklärung des Anbieters. 
 
Der Anbieter ergreift die dem Stand der Technik entsprechenden Massnahmen zum Schutz der Daten gegen unbefugte Zugriffe. Gleichwohl wird darauf hingewiesen, dass nach dem Stand der Technik nicht vollständig verhindert werden kann, dass Dritte unbefugt versuchen können, auf diese Daten Zugriff zu nehmen.
                `
            },
            {
                title: `11. Haftung`,
                text: `
                Für andere Schäden als die, die durch Verletzung von Leben, Körper und Gesundheit entstanden sind, haftet der Anbieter nur, soweit diese auf vorsätzlichem oder grob fahrlässigem Handeln oder auf schuldhafter Verletzung einer wesentlichen Vertragspflicht, d.h. einer Pflicht, deren Erfüllung die ordnungsgemässe Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmässig vertrauen darf, durch den Anbieter oder dessen Erfüllungsgehilfen beruhen. Eine darüber hinausgehende Haftung auf Schadenersatz ist ausgeschlossen. 
 
Wird eine wesentliche Vertragspflicht verletzt, ist die Haftung des Anbieters auf den vertragstypischen vorhersehbaren Schaden begrenzt. 
 
Eine Haftung nach den Bestimmungen des Produkthaftungsgesetzes bleiben hiervon unberührt. 
 
Im Übrigen haftet der Anbieter nicht für Schäden an mit den Endgeräten des Anbieters verbundenen Geräten Dritter oder für Schäden an den Endgeräten des Anbieters sowie eine fehlende Gebrauchstauglichkeit der Endgeräte, wenn diese auf falschen oder unvollständigen Angaben des Kunden bei der Kompatibilitätsüberprüfung oder Missachtung der Hinweise des Anbieters beruhen. 
 
Der Anbieter empfiehlt die Installation der Endgeräte durch einen Fachmann. Sofern die Installation durch einen vom Kunden beauftragten Dritten (z.B.: Installationsbetrieb) durchgeführt wurde, haftet der vom Kunden beauftragte Dritte für alle Schäden infolge einer unsachgemässen Installation. 
 
Eine Selbstinstallation der Endgeräte des Anbieters erfolgt stets auf eigene Gefahr. 
 
Der Anbieter haftet nicht für Schäden, die durch unsachgemässe Installation, Nutzung, Behandlung, Bedienung oder Lagerung entstehen. Mietet der Kunde Endgeräte vom Anbieter und ist ein Schaden an einem Endgerät aufgrund eines Verschuldens des Kunden entstanden, so ist der Anbieter berechtigt, bei Vertragsschluss CHF 60.- pro Gerät in Rechnung zu stellen.
 
Die Angaben bezüglich Energiesparpotenzial stammen von Quellen, die vom Anbieter als verlässlich betrachtet werden. Da die Heizkosten diversen Faktoren (beispielsweise Isolation, Lüftungsverhalten oder Temperatureinstellungen) unterliegen, die nicht im Einflussbereich des Anbieters liegen, wird keine Garantie für die Genauigkeit oder Vollständigkeit der gemachten Angaben übernommen.
 
Der Anbieter behält sich vor, den Zugriff auf die Software und die uneingeschränkte Nutzung der Applikationen des Anbieters zu unterbrechen, solange und soweit dies aus zwingenden Gründen bspw. bei notwendigen Wartungsarbeiten an Servern oder Programmen bzw. der notwendigen Infrastruktur, bei unbefugten Angriffen auf Daten oder Rechner, zur Beseitigung unvorhergesehener Sicherheitslücken oder sonstiger schwerwiegender Störungen erforderlich ist. In diesen Fällen wird soweit möglich eine eigenständige Regelintelligenz der Endgeräte aktiviert. Diese kurzzeitigen Einschränkungen begründen keine Haftung oder Gewährleistungsansprüche des Kunden.
                `
            },
            {
                title: `12. Schlussbestimmungen`,
                text: `
                Sollte eine Bestimmung dieser allgemeinen Geschäftsbedingungen unwirksam sein, berührt dies die Wirksamkeit der sonstigen Vereinbarungen nicht. 
 
Hat der Kunde keinen allgemeinen Gerichtsstand innerhalb der Europäischen Union oder ist er Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich rechtliches Sondervermögen, ist Gerichtsstand für alle sich aus dem Vertragsverhältnis ergebenden Ansprüche der Sitz des Anbieters. Der Anbieter ist jedoch berechtigt, Klage auch am Sitz des Kunden zu erheben. 
 
Es gilt ausschliesslich schweizerisches Recht. Ist der Kunde ein Verbraucher, sind darüber hinaus die zwingenden Verbraucherschutzbestimmungen anwendbar, die in dem Staat gelten, in denen der Kunde seinen gewöhnlichen Aufenthalt hat, sofern diese dem Kunden einen weitergehenden Schutz bieten. 
 
Der Anbieter ist berechtigt, diese allgemeinen Geschäftsbedingungen - soweit sie in das Vertragsverhältnis mit dem Kunden eingeführt sind - einseitig zu ändern, soweit dies zur Beseitigung nachträglich entstehender Äquivalenzstörungen oder zur Anpassung an veränderte gesetzliche oder technische Rahmenbedingungen notwendig ist. Über eine Anpassung informiert der Anbieter den Kunden unter Mitteilung des Inhaltes der geänderten Regelungen. Die Änderung wird Vertragsbestandteil, wenn der Kunde nicht binnen sechs Wochen nach Zugang der Änderungsmitteilung der Einbeziehung in das Vertragsverhältnis dem Anbieter gegenüber in Schrift- oder Textform widerspricht.
                `
            }
        ];
        let cancellationPolicyTexts = [
            {
                title: ``,
                text: `
                Sie haben das Recht, binnen 14 Tagen ohne Angabe von Gründen diesen Vertrag über die Miete bzw. den Kauf von Endgeräten zu widerrufen. 
 
Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Endgeräte in Besitz genommen haben bzw. hat. Haben Sie mehrere Endgeräte im Rahmen einer einheitlichen Bestellung bestellt und werden diese getrennt geliefert, beträgt die Widerrufsfrist vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, das letzte Endgerät in Besitz genommen haben bzw. hat. Haben Sie Endgeräte bestellt, die in mehreren Teilsendungen oder Stücken geliefert werden, beträgt die Widerrufsfrist vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die letzte Teilsendung oder das letzte Stück in Besitz genommen haben bzw. hat. 
 
Um dein Widerrufsrecht auszuüben, müssen Sie uns (Cleveron AG, Bernstrasse 1, 3066 Stettlen, info@cleveron.ch) mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. 
 
Machen Sie von dieser Möglichkeit Gebrauch, so werden wir Ihnen unverzüglich (z. B. per E-Mail) eine Bestätigung über den Eingang Ihres Widerrufs übermitteln. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
  
Folgen des Widerrufs
 
Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschliesslich der Lieferkosten (und Montagekosten), unverzüglich und spätestens binnen 30 Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Wir können die Rückzahlung verweigern, bis wir die Endgeräte wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesendet haben, je nachdem, welches der frühere Zeitpunkt ist. 
 
Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden. 
 
Für eine Verschlechterung der Endgeräte und für die gezogenen Nutzungen müssen Sie Wertersatz nur leisten, soweit die Nutzungen oder die Verschlechterung auf einen Umgang mit der Sache zurückzuführen ist, der über die Prüfung der Eigenschaften und der Funktionsweise hinausgeht. Unter „Prüfung der Eigenschaften und der Funktionsweise“ versteht man das Testen und Ausprobieren der jeweiligen Ware, wie es etwa im Ladengeschäft möglich und üblich ist.
                `
            }
        ];

        return (
            <>
                <section className="title-section first-screen">
                    <div className="wrapper">
                        <div className="title-section__main-info">
                            <h2>
                                {t('AGB')}
                            </h2>
                        </div>
                    </div>
                </section>
                <section className='faq'>
                    <div className='wrapper'>
                        <h3>
                            {t('Allgemeine Geschäftsbedingungen')}
                        </h3>
                        <ul className='faq__list'>
                            {
                                generalTermsAndConditionsTexts.map((section, i) => {
                                    return <li id={`agb-${i}`}className='faq__item'>
                                        <h4 className='faq__quastion'>{section.title}</h4>
                                        <div className='faq__answer'>
                                            {section.text}
                                       </div>
                                        <div className='faq__open-btn' onClick={() => this.openDescriptionBlock(`agb-${i}`)}>
                                            <span className='faq__btn-line' />
                                        </div>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </section>

                <section className='faq'>
                    <div className='wrapper'>
                        <h3>
                            {t('Widerrufsbelehrung Endgeräte')}
                        </h3>
                        <p>
                            {cancellationPolicyTexts[0].text}
                        </p>
                    </div>
                </section>
            </>
        )
    }
}

export default translate(PageAgb);
