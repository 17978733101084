'use strict'

import React from 'react';

import translate from "../translate";

class PageImpressum extends React.Component{
    constructor(props) {
        super(props);
    }

    openDescriptionBlock(id) {
        const element = document.getElementById(id);

        if(element.classList.contains('active')) {
            element.classList.remove('active')
        } else {
            element.classList.add('active')
        }
    }

    render() {
        const {t} = this.props;

        let generalTermsAndConditionsTexts = [
            {
                title: `Allgemeine Hinweise`,
                text: `
                    Durch die Nutzung unserer Website erklären Sie sich mit der Erhebung, Nutzung und Verarbeitung von Daten gemäß der nachfolgenden Beschreibung einverstanden.<br/>
                    Beim Besuch der Website werden Daten, wie zum Beispiel aufgerufene Seiten, Namen der aufgerufenen Datei, Uhrzeit und Datum zu statistischen Zwecken gespeichert, ohne dass ein Rüchschluss auf Ihre Person erfolgen kann. Personenbezogene Daten im Sinne des Artikel 4 DSG-VO werden nur erfasst, sofern Sie sich freiwillig für den Newsletter anmelden oder Ihre Daten im Kontaktformular angeben.<br/>
                    Ihre personenbezogenen Daten (Name, Mailadresse und weitere von Ihnen angegebene Kontaktdaten) werden selbstverständlich vertraulich behandelt, nicht an Dritte weitergeleitet und nur für interne Zwecke, wie die Optimierung der Website zur Steigerung der Benutzerfreundlichkeit oder um Ihnen auf Anfrage Informationsmaterial zukommen zu lassen, verwendet.<br/>
                    Auf Anfrage löschen wir Ihre Daten nachdem Sie uns diese übermittelt haben.<br/>
                `
            },
            {
                title: `Datenschutzerklärung für Cookies`,
                text: `
                    Unsere Website verwendet Cookies, um Ihren Website-Besuch zu optimieren. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Somit werden Nutzungshäufigkeit, Nutzeranzahl und Verhaltensweisen beim Besuch der Website evaluiert. Sollten Sie eine Nutzung von Cookies ablehnen, können Sie dies individuell über Ihren Browser konfigurieren.<br/>
               `
            },
            {
                title: `Google Analytics`,
                text: `
                    Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.<br/>
                    
                    Google Analytics verwendet so genannte "Cookies". Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.<br/>
                    
                    Die Speicherung von Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren.<br/>
                `
            },
            {
                title: `IP Anonymisierung`,
                text: `
                    Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.<br/>
                `
            },
            {
                title: `SSL- bzw. TLS-Verschlüsselung`,
                text: `
                    Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.<br/>

                    Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.<br/>
                `
            },
            {
                title: `Auskunft, Sperrung, Löschung`,
                text: `
                    Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.<br/>
                `
            }
        ];

        return (
            <>
                <section className="title-section first-screen">
                    <div className="wrapper">
                        <div className="title-section__main-info">
                            <h2>
                                {t('Impressum')}
                            </h2>
                        </div>
                    </div>
                </section>


                <section className='faq'>
                    <div className='wrapper'>
                        <p>
                            <h4>CLEVERON AG</h4>
                            Bernapark 28<br/>
                            CH-3066 Stettlen<br/>
                            Schweiz<br/><br/>

                            <h4>Geschäftsführer</h4>
                            Pietro Gagliardi<br/><br/>

                            <h4>Kontakt</h4>
                            info@cleveron.ch<br/>
                            +41 31 539 11 23
                            <br/><br/>
                            <h4>Sitz, Kontakt- und Büroadresse</h4>
                            Bernapark 28<br/>
                            CH-3066 Stettlen<br/>
                            Schweiz<br/>
                        </p>
                    </div>
                </section>

                <section className='faq'>
                    <div className='wrapper'>
                        <h3>
                            {t('Datenschutzerklärung Website')}
                        </h3>
                        <ul className='faq__list'>
                            {
                                generalTermsAndConditionsTexts.map((section, i) => {
                                    return <li id={`agb-${i}`} className='faq__item'>
                                        <h4 className='faq__quastion'>{section.title}</h4>
                                        <div className='faq__answer' dangerouslySetInnerHTML={{__html: section.text}}>
                                        </div>
                                        <div className='faq__open-btn' onClick={() => this.openDescriptionBlock(`agb-${i}`)}>
                                            <span className='faq__btn-line' />
                                        </div>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </section>
            </>
        );
    }
}

export default translate(PageImpressum);
