'use strict'

import React from 'react';
import AOS from 'aos';

import translate from "../translate";
import ContactCTA from '../contactCTA';
import paths from '../../lib/paths';
import SectionHowMuchImpact from "../section-how-much-impact";
import dietikon from '../../assets/clients/Sans-titre-2.webp';
import chiasso from '../../assets/clients/Chiasso@2x.webp';
import stadtZuerich from '../../assets/clients/Zurich@2x.webp';
import seuzach from '../../assets/clients/Seuzach@2x.webp';
import adliswil from '../../assets/clients/Adliswil2@2x.webp';
import bellinzona from '../../assets/clients/Bellinzona@2x.webp';
import bolligen from '../../assets/clients/Bolligen@2x.webp';
import mosseedorf from '../../assets/clients/Moosseedorf@2x.webp';
import locarno from '../../assets/clients/Locarno@2x.webp';
import ittingen from '../../assets/clients/Littigen@2x.webp';
import maroggia from '../../assets/clients/Maroggia@2x.webp';
import minusio from  '../../assets/clients/Minusio@2x.webp';
import kirchberg from  '../../assets/clients/Kirchberg@2x.webp';
import basserdorf from  '../../assets/clients/Basserdorf@2x.webp';
import dachsen from  '../../assets/clients/Dachsen@2x.webp';
import taiffelen from  '../../assets/clients/Tauffelen@2x.webp';
import altesTramdepot from  '../../assets/clients/AltesTramdepot_resto@2x.webp';
import bkw from  '../../assets/clients/BKW@2x.webp';
import communFounex from  '../../assets/clients/commun_founex.webp';
import hesSoFribourg from  '../../assets/clients/hes-so-fribourg.webp';
import kantonBern from  '../../assets/clients/Kanton_Bern_RGB.svg';
import vdn from  '../../assets/clients/logo-vdn-header-2021.webp';
import SectionComponent30Percent from "../section-component-30-percent";
import SectionDiscoverApplication from "../section-discover-application";
import SectionBookDemo from "../section-book-demo";
import SectionDemo from "../section-demo";

class Home extends React.Component{
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        AOS.init({
            duration: 2000,
            delay: 1000
        });
    }

    openDescriptionBlock(id) {
        const element = document.getElementById(id);

        if(element.classList.contains('active')) {
            element.classList.remove('active')
        } else {
            element.classList.add('active')
        }
    }

    render() {
        const {t} = this.props;

        let isIE = false || !!document.documentMode;


        let clients = [
            {link: 'https://www.dietikon.ch/', img: dietikon},
            {link: 'https://www.chiasso.ch', img: chiasso},
            {link: 'https://www.stadt-zuerich.ch', img: stadtZuerich},
            {link: 'http://www.seuzach.ch/de/', img: seuzach},
            {link: 'https://www.adliswil.ch', img: adliswil},
            {link: 'https://www.bellinzona.ch/', img: bellinzona},
            {link: 'https://www.bolligen.ch/', img: bolligen},
            {link: 'https://www.moosseedorf.ch/', img: mosseedorf},
            {link: 'https://www.locarno.ch', img: locarno},
            {link: 'https://www.ittigen.ch/', img: ittingen},
            {link: 'http://www.maroggia.ch/', img: maroggia},
            {link: 'https://www.minusio.ch', img: minusio},
            {link: 'https://www.kirchberg.ch/', img: kirchberg},
            {link: 'https://www.bassersdorf.ch/', img: basserdorf},
            {link: 'http://www.dachsen.ch/de/', img: dachsen},
            {link: 'https://www.taeuffelen.ch/it/startseite/', img: taiffelen},
            {link: 'https://www.altestramdepot.ch/de/home', img: altesTramdepot},
            {link: 'https://www.bkw.ch', img: bkw},
            {link: 'https://www.founex.ch/', img: communFounex},
            {link: 'https://www.hefr.ch/fr/', img: hesSoFribourg},
            {link: 'https://www.be.ch/', img: kantonBern},
            {link: 'https://www.neuchatelville.ch/', img: vdn}
        ];
        let language = this.props.i18n.resolvedLanguage;
        let videoLink = 'https://cleveron-assets.s3.amazonaws.com/media/Cleveron_Sales_Animation_DE.mp4';
        if(language === 'fr'){
            videoLink = 'https://cleveron-assets.s3.amazonaws.com/media/Cleveron_Sales_Animation_FR.mp4'
        }
        return (
            <>
                <section className="title-section first-screen">
                    <div className="wrapper">
                        <div className="title-section__main-info">
                            <h2>
                                {
                                    t('page-1-label-1', {delay: true})
                                }
                            </h2>
                        </div>
                    </div>
                </section>
                <section className="product-section">
                    <div className="product-section__row">
                        <div className="product-section__box left">
                            <div className="product-section__img-block">
                                <img className="product-section__img img img--big"    loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/full-system-small4x_PmFMzBIb6.jpg?tr=w-1600" alt="" />
                                <img className="product-section__img img img--medium" loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/full-system-small4x_PmFMzBIb6.jpg?tr=w-1400" alt="" />
                                <img className="product-section__img img img--small"  loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/full-system-small4x_PmFMzBIb6.jpg?tr=w-700" alt="" />
                            </div>
                            <div className="product-section__description active">
                                <div className="container">
                                    <h3 className="product-section__title">
                                        {t('page-1-label-2')}
                                    </h3>
                                    <div className="product-section__info">
                                        {t(`page-1-label-3`)}
                                    </div>
                                    <a onClick={() => this.props.history.push(paths.heating)} className="btn product-section__link">{t('page-1-label-4')}</a>
                                </div>
                            </div>
                        </div>
                        <div className="product-section__box right">
                            <div className="product-section__img-block">
                                <img className="product-section__img img img--big"    loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/air-quality-small4x_3mRA6LD3X.jpg?tr=w-1600" alt="" />
                                <img className="product-section__img img img--medium" loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/air-quality-small4x_3mRA6LD3X.jpg?tr=w-1400" alt="" />
                                <img className="product-section__img img img--small"  loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/air-quality-small4x_3mRA6LD3X.jpg?tr=w-700"  alt="" />
                            </div>
                            <div className="product-section__description active">
                                <div className="container">
                                    <h3 className="product-section__title">
                                        {t('page-1-label-5')}
                                    </h3>
                                    <div className="product-section__info">
                                        {t(`page-1-label-6`)}
                                    </div>
                                    <a onClick={() => this.props.history.push(paths.air)} className="btn product-section__link">{t('page-1-label-7')}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <SectionComponent30Percent {...this.props}/>
                <SectionHowMuchImpact {...this.props}/>
                <section className="video-section">
                    <div className="video-section__row">
                        <div className="video-section__text">
                            <h3>{t('page-1-label-10')}</h3>
                            <a onClick={() => this.props.history.push(paths.media)}  className="btn">{t('page-1-label-11')}</a>
                        </div>
                        <div className="video-section__video">
                            <div className="video">
                                <video controls style={{
                                    width: '100%'
                                }}
                                       poster={'https://ik.imagekit.io/sh/cleveron_ch/cover-video-1_td_1UR21q?tr=ar-5-3,w-1400'}
                                >
                                    <source
                                        src={videoLink}
                                        type="video/mp4"
                                    />
                                </video>
                            </div>
                        </div>
                    </div>
                </section>
                <SectionDiscoverApplication></SectionDiscoverApplication>
                <section className='clients'>
                    <div className='wrapper'>
                        <h3 className='text-center'>{t('page-1-label-12', {delay: false})}</h3>

                        <div className='clients__row'>
                            {
                                clients.map((client, i) => {
                                    return <div key={i} className='clients__item'>
                                        <a href={client.link} target={'_blank'}><img src={client.img} loading="lazy" alt='' className='clients__logo' /></a>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </section>
                <SectionDemo/>
                <ContactCTA />
            </>
        )
    }
}

export default translate(Home);
