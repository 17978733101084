'use strict';

import React from 'react';

import translate from "../translate";
import paths from '../../lib/paths'
import BookDemoModal from '../book-demo-modal';

class SectionDemo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openDemoModal: false
        }
    }

    render() {
        const {t} = this.props;

        let language = this.props.i18n.resolvedLanguage;
        let bookingLink = 'https://cleveron.zohobookings.eu/#/customer/67150000000017006';
        if(language === 'it'){
            bookingLink = 'https://cleveron.zohobookings.eu/#/customer/67150000000341244';
        }

        return (
            <section className="basic-section section-demo-container">
                <div className="section-demo-wrapper">
                    <div className="section-demo">
                        <div className="section-demo-item">
                            <h2 className="section-demo-item-title">
                                {t(`component-5-label-1`)}
                            </h2>
                            <a href={bookingLink} target={'_blank'} className="btn btn-green section-demo-item-button">
                                {t('component-5-label-3')}
                            </a>
                        </div>
                        <div className="section-demo-item section-demo-item-savings">
                            <h2 className="section-demo-item-title">
                                {t(`component-5-label-4`)}
                            </h2>
                            <a href={paths.demoRequest} className="btn btn-green section-demo-item-button">
                                {t('component-5-label-6')}
                            </a>
                        </div>
                        <div className="section-demo-item">
                            <h2 className="section-demo-item-title">
                                {t(`component-5-label-7`)}
                            </h2>
                            <a onClick={() => this.setState({openDemoModal: true})}  className="btn btn-green section-demo-item-button">
                                {t('component-5-label-9')}
                            </a>
                        </div>
                    </div>
                </div>
                <BookDemoModal opened={this.state.openDemoModal} onClose={() => this.setState({openDemoModal: false})} />
            </section>
        )
    }
}

export default translate(SectionDemo);
